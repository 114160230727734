/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {

                $(".atf-tweet-wrapper").wrapInner("<div class='new'></div>");


                $('.click').click(function () {
                    $('.search-submit').click();
                });

                $(document).ready(function () {
                    $(".tabs-menu a").click(function (event) {
                        event.preventDefault();
                        $(this).parent().addClass("current");
                        $(this).parent().siblings().removeClass("current");
                        var tab = $(this).attr("href");
                        $(".tab-content").not(tab).css("display", "none");
                        $(tab).fadeIn();
                    });

                    // var htmlString = $('.tribe-event-date-start').html();
                    // console.log (htmlString)

                    // $('.tribe-event-date-start'.text( htmlString + "test");
                });

                // mobile -nav
                $(document).ready(function () {

                    $(".hidden_menu").hide();

                    $('.munu').on("click", function () {
                        $(".hidden_menu").slideToggle();
                    });
                });

                // inner-mobile nav
                // $(document).ready(function () {
                //
                //     $(".inner_mobile").hide();
                //
                //     $('.menu_mobile').on("click", function () {
                //         $(".inner_mobile").slideToggle();
                //         console.log('yo')
                //     });
                // });

                $(document).ready(function () {

                    $(".slidingDiv").hide();

                    $('.show_hide').on("click", function () {
                        $(this).siblings('.slidingDiv').slideToggle();
                    });
                });


                //hero_nav
                $(document).ready(function () {

                    $(".slidingDiv").hide();

                    $('.show_hide').on("click", function () {
                        $(this).children('.slidingDiv').slideToggle();
                        $(this).children('.inner_block').toggleClass("selected");

                    });
                });

                //match by row global use
                $(function () {
                    $('.match').matchHeight({
                        byRow: true,
                        property: 'height'
                    });
                });
                $(function () {
                    $('.match_timetable').matchHeight({
                        target: $('.match_this_timetable'),
                        property: 'height'
                    });
                });
                //tweets o home page
                $(function () {
                    $('.rotatingtweets').matchHeight({
                        byRow: true,
                        property: 'height'
                    });
                });
                $(function () {
                    $('.inner_news_match').matchHeight({
                        target: $('.inner_news_match_this'),
                        property: 'height'
                    });
                });
                $(function () {
                    $('.match_type').matchHeight({
                        target: $('.match_this_type'),
                        property: 'height'
                    });
                });
                $(function () {
                    $('.match_svg').matchHeight({
                        target: $('.match_this_svg'),
                        property: 'height'
                    });
                });

                //quote
                $(function () {
                    $('.new_quote').matchHeight({
                        target: $('.match_this_quote'),
                        property: 'height'
                    });
                });
                //LIGHTBOX
                
                $(document).ready(function () {
                    $('.slider_flex').slick({
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        centerMode: true,
                        cssEase: 'linear',

                        focusOnSelect: true,
                        autoplay: true,
                        autoplaySpeed: 8000,
                        fade: true,
                        dots: true,
                        pauseOnHover: false,
                        swipeToSlide: true,
                        arrows: true
                    });
                });
                // HERO CAROUSEL
                $(document).ready(function () {
                    $('.carousel').slick({
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        centerMode: true,
                        cssEase: 'linear',

                        focusOnSelect: true,
                        autoplay: true,
                        autoplaySpeed: 8000,
                        fade: true,
                        dots: false,
                        pauseOnHover: false,
                        swipeToSlide: true,
                        arrows: false
                    });
                });
                // SINGLE SLIDER CAROUSEL
                $(document).ready(function () {
                    $('.single_slider').slick({
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        cssEase: 'linear',
                        infinite: true,
                        focusOnSelect: true,
                        autoplay: true,
                        autoplaySpeed: 8000,
                        dots: false,
                        pauseOnHover: false,
                        swipeToSlide: true,
                        arrows: false,
                        nextArrow: "<div class='arrow_wrap slick-next'> <img class='a-right control-c next ' src='" + templateUrl + "/dist/images/arrow.png'></div>"

                    });
                });
                // THREE ITEM FLEXIBLE CAROUSEL
                $(document).ready(function () {
                    $('.slider_flex_three').slick({
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        focusOnSelect: false,
                        autoplay: true,
                        cssEase: 'linear',
                        dots: false,
                        swipeToSlide: true,
                        arrows: false,
                        responsive: [
                            {
                                breakpoint: 800,
                                settings: {
                                    slidesToShow: 2,
                                    slidesToScroll: 2
                                }
                            },
                            {
                                breakpoint: 500,
                                settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1
                                }
                            }
                        ]
                    });
                });



                $('.select_comp').on('change',function(){
                    location.href = $(this).val();
                });


                //  var slideout = new Slideout({
                //    'panel': document.getElementById('panel'),
                //    'menu': document.getElementById('menu'),
                //    'padding': 280,
                //    'tolerance': 70
                // });

                //  // Toggle button
                //  document.querySelector('.toggle-button').addEventListener('click', function() {
                //    slideout.toggle();
                //  });


                // $('.match').matchHeight({
                //            byRow: true,
                //            property: 'height'
                //        });

                // JavaScript to be fired on all pages

                //   if ($(window).width() > 768) {

                //     $( ".nav-primary li a" ).mouseover(

                //       function() {
                //         $( ".nav-primary li a" ).next().fadeOut( "slow", "linear" );
                //         $( this ).next().fadeIn( "slow", "linear" );
                //       }

                //     );

                // }

                //  $('.nav-primary ul .nav-main-item .nav-main-link').on('touchstart', function (e) {
                //   'use strict'; //satisfy code inspectors
                //   var link = $(this); //preselect the link
                //   if (link.hasClass('hover')) {
                //       return true;
                //   } else {
                //       link.addClass('hover');
                //       $('.nav-primary ul .nav-main-item .nav-main-link').not(this).removeClass('hover');
                //       e.preventDefault();
                //       return false; //extra, and to make sure the function has consistent return points
                //   }
                // });

                //looks for iframes wraps and adapts the height and width
                (function (window, document, undefined) {

                    /*
                     * Grab all iframes on the page or return
                     */
                    var iframes = document.getElementsByTagName('iframe');

                    /*
                     * Loop through the iframes array
                     */
                    for (var i = 0; i < iframes.length; i++) {

                        var iframe = iframes[i],

                            /*
                             * RegExp, extend this if you need more players
                             */
                            players = /www.youtube.com|player.vimeo.com/;

                        /*
                         * If the RegExp pattern exists within the current iframe
                         */
                        if (iframe.src.search(players) > 0) {

                            /*
                             * Calculate the video ratio based on the iframe's w/h dimensions
                             */
                            var videoRatio = ( iframe.height / iframe.width ) * 100;

                            /*
                             * Replace the iframe's dimensions and position
                             * the iframe absolute, this is the trick to emulate
                             * the video ratio
                             */
                            iframe.style.position = 'absolute';
                            iframe.style.top = '0';
                            iframe.style.left = '0';
                            iframe.width = '100%';
                            iframe.height = '100%';

                            /*
                             * Wrap the iframe in a new <div> which uses a
                             * dynamically fetched padding-top property based
                             * on the video's w/h dimensions
                             */
                            var wrap = document.createElement('div');
                            wrap.className = 'fluid-vids';
                            wrap.style.width = '100%';
                            wrap.style.position = 'relative';
                            wrap.style.paddingTop = videoRatio + '%';

                            /*
                             * Add the iframe inside our newly created <div>
                             */
                            var iframeParent = iframe.parentNode;
                            iframeParent.insertBefore(wrap, iframe);
                            wrap.appendChild(iframe);

                        }

                    }

                })(window, document);

                // HOME HERO CAROUSEL
                $(document).ready(function () {
                    $('.gallery_home').slick({
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        centerMode: true,
                        focusOnSelect: true,
                        autoplay: true,
                        autoplaySpeed: 8000,
                        fade: true,
                        dots: false,
                        pauseOnHover: false,
                        swipeToSlide: true,
                        arrows: false,
                    });
                });
                // MUTE AND AUTO PLAY HOME PAGE VIDEO
                function mute() {
                    $('iframe')[0].contentWindow.postMessage('{"event":"command","func":"' + 'mute' + '","args":""}', '*');
                    $('iframe')[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');

                }

                $('iframe').load(function () {
                    mute();
                    $(".fluid-vids").css("padding-top", "75%");
                });
                // HOME quote
                $(document).ready(function () {
                    $('.quote').slick({
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        centerMode: true,
                        focusOnSelect: true,
                        autoplay: true,
                        autoplaySpeed: 8000,
                        fade: true,
                        dots: false,
                        pauseOnHover: false,
                        swipeToSlide: true,
                        arrows: false
                    });
                });

                $(document).ready(function () {
                    var isClicked = "";

                    $(".foot-drop").hide();

                    $('.foot.menu-item-has-children').on("click", function (event) {
                        if (isClicked == "no" || isClicked == "") {
                            event.preventDefault();
                            $(this).children('.foot-drop').slideToggle();
                            isClicked = "yes";
                        }
                        else {
                            isClicked = "no";
                        }
                    });
                });
                //SCROLL TO ANCHOR TAGS DELAYED FOR EXTERNAL PAGES
// to top right away
              //  if (window.location.hash) scroll(0, 0);
// void some browsers issue
              //  setTimeout(function () {
               //     scroll(0, 0);
             //  }, 1);

// any position
               // $(function () {
                    // your current click function
               //     $('.scroll').on('click', function (e) {
                   //     e.preventDefault();
                   //     $('html, body').animate({
                   //         scrollTop: $($(this).attr('href')).offset().top - 120
                   //     }, 1000, 'swing');
                 //   });
                    // *only* if we have anchor on the url
                 //   if (window.location.hash) {
                        // smooth scroll to the anchor id
                //        $('html, body').animate({
                  //          scrollTop: $(window.location.hash).offset().top - 120
                 //       }, 1000, 'swing');
                 //   }
             //   });

//NEWS PAGE SELECT
                $('.category_select').change(function () {
                    var newcat = $('select.category_select option:selected').val();
                 //   console.log(newcat);
                    window.location.replace(newcat);
                });

            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired

                // $("#tab-results").wrap("<div class='results show_hide'></div>");
                // $(" #tab-fixtures").wrap("<div class='fixtures show_hide'></div>");
                // $("#tab-scorers").wrap("<div class='scorers show_hide'></div>");


                // function sticky_relocate() {
                //     var window_top = $(window).scrollTop();
                //     var div_top = $('#sticky-anchor').offset().top;
                //     if (window_top > div_top) {
                //         $('#sticky').addClass('stick');
                //         $('#sticky-anchor').height($('#sticky').outerHeight());
                //     } else {
                //         $('#sticky').removeClass('stick');
                //         $('#sticky-anchor').height(0);
                //     }
                // }


                // $(function () {
                //     $(window).scroll(sticky_relocate);
                //     sticky_relocate();
                // });

                // var dir = 1;
                // var MIN_TOP = 200;
                // var MAX_TOP = 350;

                // function autoscroll() {
                //     var window_top = $(window).scrollTop() + dir;
                //     if (window_top >= MAX_TOP) {
                //         window_top = MAX_TOP;
                //         dir = -1;
                //     } else if (window_top <= MIN_TOP) {
                //         window_top = MIN_TOP;
                //         dir = 1;
                //     }
                //     $(window).scrollTop(window_top);
                //     window.setTimeout(autoscroll, 100);
                // }

                //
                // $(function () {
                //     $(window).scroll(sticky_relocate);
                //     sticky_relocate();
                // });

function sticky_relocate() {
                     var window_top = $(window).scrollTop();
                   // var div_top = $('#sticky-anchor').offset().top;
                   var div_top = $('#content-anchor').offset().top;

                   if (window_top > div_top) {

                       $('#sticky').addClass('stick');
                      $('.header .headerLogo img').addClass('newHead');
                      $('.hidden_menu').addClass('newTop');
                       
                       $('#sticky-anchor').height($('#sticky').outerHeight());
                       //$('.white_icon').addClass('show');
                       //$('.headerLogo').fadeOut();
                       


                       // Get the height of #sticky
                       // outerHeight() gets height including padding and borders
                       var phantomHeight = $('#sticky').outerHeight();

                       // Set the height of $sticky-phantom
                       $('#sticky-phantom').height(phantomHeight).show();


                   } else {
                    //$('.headerLogo').fadeIn();
                       $('#sticky').removeClass('stick');
                        $('.header .headerLogo img').removeClass('newHead');
                       $('#sticky-anchor').height(0);
                       $('#sticky-phantom').hide();
                       $('.hidden_menu').removeClass('newTop');
                       //$('.white_icon').removeClass('show');
                     }
                 }


               // $(function () {
               //     $(window).scroll(sticky_relocate);
               //     sticky_relocate();
               // });
         

         // $(function () {
               //     $(window).scroll(sticky_relocate);
               //     sticky_relocate();
               // });


            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page

                //sacked off cunning sticky nav instead simpler but stillwant to fire the phantom

                // var phantomHeight = $('#sticky').outerHeight();
                // $('#sticky-phantom').height(phantomHeight).show();



                $('.home_finder .inner_wrapper.bottom').hide();
                $('.tabs-menu .block').on('click', function () {
                    $('.inner_wrapper.bottom').show();
                    $('#closeBar').show();
                    $(this).addClass('current').siblings().removeClass('current');
                });
                $('#closeBar').on('click', function () {
                    $('.inner_wrapper.bottom').hide();
                });


            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // clubs page
        'clubs': {
            init: function () {
                // JavaScript to be fired on the home page
                $('.national_tab').on('click', function () {
                    $(this).addClass('active').siblings().removeClass('active');
                    $('.national').fadeIn().siblings().hide();
                });
                $('.regional_tab').on('click', function () {
                    $(this).addClass('active').siblings().removeClass('active');
                    $('.regional').fadeIn().siblings().hide();
                });
                $('.county_tab').on('click', function () {
                    $(this).addClass('active').siblings().removeClass('active');
                    $('.county').fadeIn().siblings().hide();
                })

            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
                function loadQueryResultsClubs(location) {
                    $('.club_wrap').load('https://www.englandkorfball.co.uk/teams/?item=' + location + ' #club_wrap');
                }

                window.onload = loadQueryResultsClubs('33105');

                $('.select_club_national').change(function () {
                    var url_club = $('select.select_club_national option:selected').val();
                    $('.club_wrap').empty();
                    loadQueryResultsClubs(url_club);
                });
                $('.select_club_regional').change(function () {
                    var url_club = $('select.select_club_regional option:selected').val();
                    $('.club_wrap').empty();
                    loadQueryResultsClubs(url_club);
                });

                $('.select_club_county').change(function () {
                    var url_club = $('select.select_club_county option:selected').val();
                    $('.club_wrap').empty();
                    loadQueryResultsClubs(url_club);
                });

            }
        },
        // Home page
        'national': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS

                function loadQueryResults(location) {
                    $('#content').load('https://www.englandkorfball.co.uk/league/' + location + ' #content_target');
                    $('.tabs').load('https://www.englandkorfball.co.uk/league/' + location + ' #tab_content', function () {
                        $("#tabs .panel").hide();

                        $('.tabs li a').on("click", function (event) {
                            event.stopImmediatePropagation();
                            event.preventDefault();
                            event.stopPropagation();
                            $(this).toggleClass("selected");
                            var tab = $(this).attr("href");
                            $(tab).stop();
                            $(tab).slideToggle();
                        });

                        $(".tabs .fixture_block .fixtures tbody tr td").hide();

                        $('.tabs .fixture_block .fixtures tbody tr th').on("click", function (event) {
                            event.stopImmediatePropagation();
                            event.preventDefault();
                            $(this).parent('tr').siblings('tr').children('td').slideToggle();
                        });

                    });
                    return false;
                }

                window.onload = loadQueryResults('ekl-premier-division/');

                $('.select').change(function () {
                    var url = $('select.select option:selected').val();
                    $('#content').empty();
                    $('.tabs').empty();
                    loadQueryResults(url);
                });

            }
        },
        // Home page
        'regional': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS

                function loadQueryResults(location) {
                    $('#content').load('https://www.englandkorfball.co.uk/league/' + location + ' #content_target');
                    $('.tabs').load('https://www.englandkorfball.co.uk/league/' + location + ' #tab_content', function () {
                        $("#tabs .panel").hide();

                        $('.tabs li a').on("click", function (event) {
                            event.stopImmediatePropagation();
                            event.preventDefault();
                            event.stopPropagation();
                            $(this).toggleClass("selected");
                            var tab = $(this).attr("href");
                            $(tab).stop();
                            $(tab).slideToggle();
                        });

                        $(".tabs .fixture_block .fixtures tbody tr td").hide();

                        $('.tabs .fixture_block .fixtures tbody tr th').on("click", function (event) {
                            event.stopImmediatePropagation();
                            event.preventDefault();
                            $(this).parent('tr').siblings('tr').children('td').slideToggle();
                        });

                    });
                    return false;
                }

                //on page load load league one
                window.onload = loadQueryResults('london-regional-league-1');
                //loads up button with permalink link on page load
                window.onload = $(".view_all").attr("href", 'https://www.englandkorfball.co.uk/league/london-regional-league-1');

                $('.select').change(function () {
                    var url = $('select.select option:selected').val();
                    $('#content').empty();
                    $('.tabs').empty();
                    //passes the select value to load script
                    loadQueryResults(url);
                    //loads up button with permalink link on page load
                    $(".view_all").attr("href", 'https://www.englandkorfball.co.uk/league/' + url);
                });

            }
        },
        // Home page
        'contact': {
            init: function () {
                // JavaScript to be fired on the home page
                $(document).ready(function () {

                    $(".articles").hide();

                    $('.category').on("click", function () {
                        $(this).children('.articles').slideToggle();
                        $(this).toggleClass("selected");

                    });
                });

            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'blog': {
            init: function () {
                // JavaScript to be fired on the about us page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
                $(function () {
                    $('.news_match').matchHeight({
                        byRow: true,
                        property: 'height'
                    });
                });
            }
        },
        'local': {
            init: function () {
                // JavaScript to be fired on the county page
            },
            finalize: function () {
                // JavaScript to be fired on the county page after the init JS
                function loadQueryResults(location) {
                    $('#content').load('https://www.englandkorfball.co.uk/league/' + location + ' #content_target');
                    $('.tabs').load('https://www.englandkorfball.co.uk/league/' + location + ' #tab_content', function () {
                        $("#tabs .panel").hide();

                        $('.tabs li a').on("click", function (event) {
                            event.stopImmediatePropagation();
                            event.preventDefault();
                            event.stopPropagation();
                            $(this).toggleClass("selected");
                            var tab = $(this).attr("href");
                            $(tab).stop();
                            $(tab).slideToggle();
                        });

                        $(".tabs .fixture_block .fixtures tbody tr td").hide();

                        $('.tabs .fixture_block .fixtures tbody tr th').on("click", function (event) {
                            event.stopImmediatePropagation();
                            event.preventDefault();
                            $(this).parent('tr').siblings('tr').children('td').slideToggle();
                        });

                    });
                    return false;
                }

                window.onload = loadQueryResults('london-league-2');
                //loads up button with permalink link on page load
                window.onload = $(".view_all").attr("href", 'https://www.englandkorfball.co.uk/league/london-league-2');

                $('.select').change(function () {
                    var url = $('select.select option:selected').val();
                    $('#content').empty();
                    $('.tabs').empty();
                    loadQueryResults(url);
                    //loads up button with permalink link on page load
                    $(".view_all").attr("href", 'https://www.englandkorfball.co.uk/league/' + url);
                });
            }
        },

                'international': {
            init: function () {
                // JavaScript to be fired on the county page
            },
            finalize: function () {
                // JavaScript to be fired on the county page after the init JS
                function loadQueryResults(location) {
                    $('#content').load('https://www.englandkorfball.co.uk/league/' + location + ' #content_target');
                    $('.tabs').load('https://www.englandkorfball.co.uk/league/' + location + ' #tab_content', function () {
                        $("#tabs .panel").hide();

                        $('.tabs li a').on("click", function (event) {
                            event.stopImmediatePropagation();
                            event.preventDefault();
                            event.stopPropagation();
                            $(this).toggleClass("selected");
                            var tab = $(this).attr("href");
                            $(tab).stop();
                            $(tab).slideToggle();
                        });

                        $(".tabs .fixture_block .fixtures tbody tr td").hide();

                        $('.tabs .fixture_block .fixtures tbody tr th').on("click", function (event) {
                            event.stopImmediatePropagation();
                            event.preventDefault();
                            $(this).parent('tr').siblings('tr').children('td').slideToggle();
                        });

                    });
                    return false;
                }

                window.onload = loadQueryResults('u17-world-cup-pool-a-2016');
                //loads up button with permalink link on page load
                window.onload = $(".view_all").attr("href", 'https://www.englandkorfball.co.uk/league/u17-world-cup-pool-a-2016');

                $('.select').change(function () {
                    var url = $('select.select option:selected').val();
                    $('#content').empty();
                    $('.tabs').empty();
                    loadQueryResults(url);
                    //loads up button with permalink link on page load
                    $(".view_all").attr("href", 'https://www.englandkorfball.co.uk/league/' + url);
                });
            }
        },
                       'juniors': {
            init: function () {
                // JavaScript to be fired on the county page
            },
            finalize: function () {
                // JavaScript to be fired on the county page after the init JS
                function loadQueryResults(location) {
                    $('#content').load('https://www.englandkorfball.co.uk/league/' + location + ' #content_target');
                    $('.tabs').load('https://www.englandkorfball.co.uk/league/' + location + ' #tab_content', function () {
                        $("#tabs .panel").hide();

                        $('.tabs li a').on("click", function (event) {
                            event.stopImmediatePropagation();
                            event.preventDefault();
                            event.stopPropagation();
                            $(this).toggleClass("selected");
                            var tab = $(this).attr("href");
                            $(tab).stop();
                            $(tab).slideToggle();
                        });

                        $(".tabs .fixture_block .fixtures tbody tr td").hide();

                        $('.tabs .fixture_block .fixtures tbody tr th').on("click", function (event) {
                            event.stopImmediatePropagation();
                            event.preventDefault();
                            $(this).parent('tr').siblings('tr').children('td').slideToggle();
                        });

                    });
                    return false;
                }

                window.onload = loadQueryResults('inter-areas-u11-pool-2017');
                //loads up button with permalink link on page load
                window.onload = $(".view_all").attr("href", 'https://www.englandkorfball.co.uk/league/inter-areas-u11-pool-2017');

                $('.select').change(function () {
                    var url = $('select.select option:selected').val();
                    $('#content').empty();
                    $('.tabs').empty();
                    loadQueryResults(url);
                    //loads up button with permalink link on page load
                    $(".view_all").attr("href", 'https://www.englandkorfball.co.uk/league/' + url);
                });
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
